import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { AuthChangeEvent } from '@supabase/supabase-js'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

// eslint-disable-next-line unused-imports/no-unused-vars
const LoginPage = () => {
  const supabase = useSupabaseClient()
  const router = useRouter()

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(
      async (event: AuthChangeEvent, _session) => {
        if (event == 'SIGNED_IN') {
          router.push('/signin')
        }
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [router, supabase])

  return (
    <Auth
      appearance={{ theme: ThemeSupa }}
      supabaseClient={supabase}
      providers={['google', 'github']}
      socialLayout="horizontal"
    />
  )
}

export default LoginPage
