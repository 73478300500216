import { Spinner } from '@geist-ui/core'
import { useUser } from '@supabase/auth-helpers-react'
import { useRouter } from 'next/router'
import * as React from 'react'

import LoginPage from '@/pages/login'

/**
 * SVGR Support
 * Caveat: No React Props Type.
 *
 * You can override the next-env if the type is important to you
 * @see https://stackoverflow.com/questions/68103844/how-to-override-next-js-svg-module-declaration
 */

// !STARTERCONF -> Select !STARTERCONF and CMD + SHIFT + F
// Before you begin editing, follow all comments with `STARTERCONF`,
// to customize the default configuration.

const HomePage = () => {
  const user = useUser()
  const router = useRouter()
  React.useEffect(() => {
    if (user) router.push('/dashboard')
  }, [user, router])

  return (
    <div className="lg:ml-96 lg:mr-96">
      {user ? <Spinner /> : <LoginPage />}
    </div>
  )
}
export default HomePage
